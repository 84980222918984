// Need this to grab the location in workers

// eslint-disable-next-line no-restricted-globals
const currentLocation = self.location;

const domainParts = currentLocation.hostname.replace('www.', '').split('.');

let subDomainPrefix = '';
let environment = 'production';
let wmsUrl = '';
let configId = '';

const setPrefix = (arr: string[], prefix: string) => {
  if (
    arr.some(r => currentLocation.hostname === r || (domainParts[1] && domainParts[1].includes(r)))
  ) {
    subDomainPrefix = `${prefix}`;
    environment = prefix;
  }
};

setPrefix(['localhost', 'qa', 'qa-'], 'qa');
setPrefix(['staging', 'staging-'], 'staging');

const isCnDomain = domainParts[domainParts.length - 1] === 'cn';

// Added for testing multi-region clusters
// const urlParams = new URLSearchParams(currentLocation.search);
// const isTestMode = urlParams.get('test_mode');

// if (isTestMode === '1') {
//   const region = urlParams.get('region');

//   wmsUrl = `prod-awe.${region}.nuralogix.ai`;
//   configId = urlParams.get('configId')!;
// } else {
//   wmsUrl = isCnDomain
//     ? `${subDomainPrefix}-awe.nuralogix.cn`
//     : `wms.na-east.${subDomainPrefix}.camera-measure.me`;
// }

wmsUrl = isCnDomain
  ? `${subDomainPrefix}-awe.nuralogix.cn`
  : `wms.na-east.${subDomainPrefix}.camera-measure.me`;

if (isCnDomain) {
  wmsUrl = subDomainPrefix === '' ? 'awe.nuralogix.cn' : `${subDomainPrefix}-awe.nuralogix.cn`;
} else {
  wmsUrl =
    subDomainPrefix === ''
      ? 'wms.camera-measure.me'
      : `wms.na-east.${subDomainPrefix}.camera-measure.me`;
}

export { wmsUrl, configId };
