export const publicKey = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqJIwUj+X7R0wMBgA4r5L
EqTOqFkB5INd/NZaJCLYDGCRB2H/eZ4U8J//uXczuDSt/raXzoBZSZN9WWZuSeON
noGm+HhpDDH2gNt7itpgOO1YE65701A9pnuuuwjrfRPPWwSzJTaH2+1kgy0vsec+
k+XuuU2Mn4y6yu7ExSA5Yf50SKfL4jC9BKWa2WYTT+ee2mIXlZzqBQDE/UQFR3hB
w1KMKgphLHlVcOzxtNoO3RyTFm2H+5TTsYzYMUOHCQkQ+BmaM3alZiTQQ3vh+dBd
r3ebDtVWmIeTBooQPL+nutEjQhGy5JyMIJBHyclHEWlZmVSBMJbRwF6GFMOvsJwQ
2wIDAQAB
-----END PUBLIC KEY-----
`;
